<template>
  <div class="w-full h-full">
    <div class="bas mt-6 pb-2">
      <div>
        <div class="flex mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            Destination
          </div>
          <div class=" w-1/2 text-right valeur">
<!--            {{ devis.zone }} | -->
            <span v-if="donnes === null">{{ devis.destination }}</span>
            <span v-if="donnes !== null"> {{donnes.meta.country.name}} </span>
          </div>
        </div>

        <div class="flex mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            Durée du voyage
          </div>
          <div class=" w-1/2 text-right valeur">
            <span v-if="donnes === null"> {{ devis.dure }} jour<span v-if="devis.dure > 1">s</span> </span>
            <span v-if="donnes !== null">{{dureOnDate(donnes.meta.endDate, donnes.meta.startDate)}} Jours</span>
          </div>
        </div>

        <div class="flex mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            Date de prise d'effet
          </div>
          <div class=" w-1/2 text-right valeur">
            <span v-if="donnes === null"> {{ devis.depart.substr(0, 10) }} </span>
            <span v-if="donnes !== null">{{conversion(donnes.meta.startDate)}}</span>
          </div>
        </div>

        <div class="flex mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            Votre age
          </div>
          <div class="w-1/2 text-right valeur">
            <span v-if="donnes === null">{{ calculeAge(devis.naissance) }}  </span>
            <span v-if="donnes !== null">{{calculeAge(donnes.meta.birthDay)}}</span> ans
          </div>
        </div>

        <div class="flex mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            Maladie chronique
          </div>
          <div class="w-1/2 text-right valeur">
            <span v-if="donnes === null">{{ devis.sante.health.toUpperCase() }}</span>
            <span v-if="donnes !== null">{{track('maladie')}}</span>
          </div>
        </div>

        <div class="flex mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            Traitement médical
          </div>
          <div class="w-1/2 text-right valeur">
            <span v-if="donnes === null">{{ devis.sante.traitment.toUpperCase() }}</span>
            <span v-if="donnes !== null">{{track('traitement')}}</span>
          </div>
        </div>

        <div class="flex mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            Traitement dentaire
          </div>
          <div class="w-1/2 text-right valeur">
            <span v-if="donnes === null">{{ devis.sante.dent.toUpperCase() }}</span>
            <span v-if="donnes !== null">{{track('dent')}}</span>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      devis: null
    }
  },
  mounted () {
    this.devis = this.$store.getters.trackFirstDevis
    console.log(this.donnes)
  },
  methods: {
    calculeAge (date) {
      return new Date().getFullYear() - new Date(date).getFullYear()
    },

    dureOnDate (start, end) {
      const temps = end - start
      const jours = temps / (1000 * 3600 * 24)
      return jours.toFixed(0)
    },

    conversion (index) {
      return new Date(index).toLocaleDateString()
    },

    track (index) {
      let response = 'Non'
      if (index === 'maladie') {
        if (this.donnes.meta.haveChronicDisease) {
          response = 'Oui'
        }
      }
      if (index === 'dent') {
        if (this.donnes.meta.hasDentalCare) {
          response = 'Oui'
        }
      }
      if (index === 'traitement') {
        if (this.donnes.meta.haveMedicalTreatment) {
          response = 'Oui'
        }
      }
      return response
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";

div{
  font-family: $font-default;
}

.denom{
  font-style: normal;
  font-weight: normal;
  font-size: 17.6737px;
  line-height: 9px;
  color: #000000;
}
.valeur{
  font-weight: bold;
  font-size: 17.6737px;
  line-height: 53%;
  color: #000000;
}
.reduction{
  .denom{
    font-style: normal;
    font-weight: normal;
    font-size: 15.1489px;
    line-height: 9px;
    color: #F44336;
  }
  .valeur{
    font-weight: bold;
    font-size: 15.1489px;
    line-height: 53%;
    color: #F44336;
  }
}
.sous-titre{
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
}
.titre{
  font-weight: bold;
  font-size: 32px;
  line-height: 140%;
}
@media screen and (max-width: 700px){
  .denom, .valeur{
    font-size: 12px;
  }
}
</style>
