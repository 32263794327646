<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
          class="success-container h-full p-6"
        >
          <div class="titre text-center pt-4">Je soussigné(e)
            <span v-if="donnes !== null">{{donnes.user.fullName}}</span> et <span v-if="donnes !== null">{{donnes.user.profession}}</span>, déclare que les réponses données ci-dessus sont sincères et exactes à ma connaissance. En cas de fausses déclarations, il sera fait application des Articles 18 et 19 du Code CIMA.</div>

            <div class="button continuer w-full mt-6" @click="yes">J'approuve les conditions </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'

export default {
  name: 'Success',
  components: { PopupBase },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      donnes: null

    }
  },
  computed: {
  },
  created () {
    this.donnes = this.$store.getters.trackFirstDevis
  },
  methods: {
    fermer () {
      this.activeOk = false
      this.$emit('oga', false)
    },
    yes () {
      this.activeOk = false
      this.$emit('oga', true)
    },
    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
      this.activeOk = false
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 50%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      padding: 0px ;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .titre{
      font-weight: 500;
      font-size: 20px;
      line-height: 219%;
      text-align: center;
      color: #000000;
    }

    .button{
      background-color: $base-color;
      height: 57.36px;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: $white;
      border: 1px solid #0269AD;
      box-sizing: border-box;
      border-radius: 10px;
    }

    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre{
        font-size: 18px;
      }
      .bloc1{
        width: 100%;
      }
      .bloc2{
        width: 100%;
        font-size: 18px;
      }
      .button{
        width: 100%;
        font-size: 16px;
      }
      .cardinal{
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
