<template>
  <div class="w-full h-full">
    <div class="bas mt-6 pb-2">
      <div>
        <div class="flex mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            Loyer mensuel
          </div>
          <div class=" w-1/2 text-right valeur">
            <span v-if="donnes === null">{{ devis.valeur.loyer.toLocaleString() }} </span>
            <span v-if="donnes !== null">{{donnes.meta.rent.toLocaleString()}}</span> FCFA
          </div>
        </div>

        <div class="flex mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            Valeur du contenu
          </div>
          <div class=" w-1/2 text-right valeur">
            <span v-if="donnes === null">{{ devis.valeur.valeurContenu.toLocaleString() }} </span>
            <span v-if="donnes !== null">{{donnes.meta.contentValue.toLocaleString()}}</span> FCFA
          </div>
        </div>

        <div class="flex mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            <span v-if="donnes === null">Garanties financières</span>
            <span v-if="donnes !== null">Matériel Informatique</span>
          </div>
          <div class=" w-1/2 text-right valeur">
            <span v-if="donnes === null">{{ devis.informatique.garentie.toLocaleString() }}</span>
            <span v-if="donnes !== null">{{donnes.meta.techValue.toLocaleString()}}</span> FCFA
          </div>
        </div>

        <div class="flex mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            Valeur du bâtiment
          </div>
          <div class="w-1/2 text-right valeur">
            <span v-if="donnes === null">{{ devis.valeur.valeurBatiment.toLocaleString() }} </span>
            <span v-if="donnes !== null">{{donnes.meta.buildingValue.toLocaleString()}}</span> FCFA
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      devis: null
    }
  },
  mounted () {
    this.devis = this.$store.getters.trackFirstDevis
    this.donnes = this.$store.getters.souscriptionDetail
    console.log('habitation')
    console.log(this.donnes)
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";

div{
  font-family: $font-default;
}

.denom{
  font-style: normal;
  font-weight: normal;
  font-size: 17.6737px;
  line-height: 9px;
  color: #000000;
}
.valeur{
  font-weight: bold;
  font-size: 17.6737px;
  line-height: 53%;
  color: #000000;
}
.reduction{
  .denom{
    font-style: normal;
    font-weight: normal;
    font-size: 15.1489px;
    line-height: 9px;
    color: #F44336;
  }
  .valeur{
    font-weight: bold;
    font-size: 15.1489px;
    line-height: 53%;
    color: #F44336;
  }
}
.sous-titre{
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
}
.titre{
  font-weight: bold;
  font-size: 32px;
  line-height: 140%;
}
@media screen and (max-width: 700px){
  .denom, .valeur{
    font-size: 12px;
  }
}
</style>
