<template>
  <div class="w-full h-full">
    <condition-user :want-to-make-offer="activeCondition" v-if="activeCondition" @oga="retouCondition"/>
    <div class="titre1 md:text-left text-center">
      DETAILS DE VOTRE
      <span v-if="product === 'automobile' || product === 'auto'">VEHICULE</span>
      <span v-if="product === 'moto'">MOTO</span>
      <span v-if="product === 'mrh' || product === 'habitation'">ASSURANCE MRH</span>
      <span v-if="product === 'voyage'">VOYAGE</span>
    </div>

    <div class="baniere flex items-center mt-5 pl-4 pr-4">
      <img src="../../../assets/icons/vehicule.svg" alt="" class="md:h-12 h-10 mr-4" v-if="product === 'automobile' || product === 'auto'">
      <img src="../../../assets/icons/moto.svg" alt="" class="md:h-12 h-10 mr-4" v-if="product === 'moto'">
      <img src="../../../assets/icons/mrh.svg" alt="" class="md:h-12 h-10 mr-4" v-if="product === 'mrh'">
      <img src="../../../assets/icons/voyage.svg" alt="" class="md:h-12 h-10 mr-4" v-if="product === 'voyage'">

      <div class="w-2/4">
        <div class="marque"  v-if="product !== 'habitation' || product !== 'mrh' && product !== 'voyage'">
         <span v-if="donnes === null">
           {{ devis.marque }} |
           <span v-if="devis.plaque !== undefined"> {{ devis.plaque }}</span>
           <span v-if="product === 'moto'"> {{ devis.model }}</span>
         </span>

          <span v-if="donnes !== null">
<!--            MOTO-->
            <span v-if="product === 'moto'">
               {{ donnes.meta.motorBrand }} | {{donnes.meta.motoRegistration}}
            </span>

            <span v-if="product === 'auto' || product === 'automobile'">
               {{ donnes.meta.carBrand }} | {{donnes.meta.carRegistration}}
            </span>
          </span>
        </div>

        <div class="marque" v-if="product === 'mrh' || product === 'habitation'">
          <span v-if="donnes === null">{{ devis.model.model}}, {{ devis.adresse.ville}}</span>
          <span v-if="donnes !== null">{{ donnes.meta.habitationType.toUpperCase() }}, {{donnes.meta.ville.toUpperCase()}}</span>
        </div>

        <div class="marque" v-if="product === 'voyage'">
         <span v-if="donnes === null"> {{ devis.destination }}  | {{devis.dure}} JOURS</span>
          <span v-if="donnes !== null"> {{donnes.meta.ownerCountry.name}} - {{ donnes.meta.country.name }}</span>
        </div>

        <div class="desc">
          <span v-if="product === 'automobile' || product === 'auto'">Automobile</span>
          <span v-if="product === 'moto'">Moto</span>
          <span v-if="product === 'mrh' || product === 'habitation'">
            <span v-if="donnes === null">Devis expire dans 12 jours</span>
            <span v-if="donnes !== null">MRH</span>
          </span>
          <span v-if="product === 'voyage'">
            <span v-if="donnes === null">Devis expire dans 12 jours</span>
             <span v-if="donnes !== null">Voyage</span>
          </span>
        </div>
      </div>

      <div v-if="donnes" class="w-2/5">
        <buton
          v-if="donnes.meta && donnes.meta.periodiquePlaque"
          label="Changer le numéro"
          icon="edit"
          size="14px"
          height="33px"
          class="w-full"
          background="#F8F8F8"
          radius="100px"
          color="#0269AD"
          :iconHeight="15"
          :iconWidth="15"
          @info="updateProfile"
        />
      </div>

    </div>

    <div class="">
      <recapAuto v-if="product === 'automobile' || product === 'auto'" :donnes="donnes"/>
      <recapMoto v-if="product === 'moto'" :donnes="donnes"/>
      <recapMrh v-if="product === 'mrh' || product === 'habitation'" :donnes="donnes"/>
      <recapVoyage v-if="product === 'voyage'" :donnes="donnes"/>
    </div>

    <div class="baniere1 flex items-center mt-5" v-if="donnes === null">
      <div class="w-full text-center">
        <div class="sous-titre">TOTAL A PAYER</div>
        <div class="titre">{{devis.devis.computed}} Fr</div>
      </div>
    </div>

    <div class="w-full mt-2 flex justify-center" v-if="donnes === null">
      <div class="button w-full mt-5" @click="paye">Confirmer et payer</div>
    </div>
  </div>
</template>

<script>
import conditionUser from '@/components/popup/conditionUser'
import recapAuto from '@/components/cedro/automobile/recapAuto'
import recapMoto from '@/components/cedro/moto/recapMoto'
import recapMrh from '@/components/cedro/mrh/recapMrh'
import recapVoyage from '@/components/cedro/voyage/recapVoyage'
import buton from '@/components/helper/add/button'

export default {
  name: 'index',
  components: {
    conditionUser,
    recapAuto,
    recapMoto,
    recapMrh,
    recapVoyage,
    buton
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      activeCondition: false,
      product: null,
      devis: null
    }
  },

  created () {
    if (this.donnes === null) {
      this.donnes = this.$store.getters.souscriptionDetail
    }
  },

  mounted () {
    if (this.donnes === null) {
      this.product = this.$store.getters.trackProduct.toLowerCase()
      this.devis = this.$store.getters.trackFirstDevis
    } else {
      this.product = this.donnes.product.categorie.toLowerCase()
      // console.log(this.product)
    }

    console.log('detaillllll')
    console.log(this.donnes)
    console.log(this.product)
  },

  methods: {
    paye () {
      this.activeCondition = true
    },

    updateProfile () {
      this.$router.push('modification')
    },

    retouCondition (answer) {
      if (answer === true) {
        this.$emit('info', answer)
      }
      this.activeCondition = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.baniere{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  background-color: #fff;
  height: 104px;
}
.titre1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  color: #000000;
}
.marque{
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  color: #000000;
}
.desc{
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  color: #909090;
}
.baniere1{
  width: 100%;
  border-radius: 20px;
  height: 102.26px;
  background: #F8E9B8;
  .titre{
    color: #FF9900;
    font-weight: bold;
    font-size: 38px;
  }
  .sous-titre{
    font-size: 11.2778px;
    line-height: 15px;
    text-transform: uppercase;

    color: #000000;
  }
}
.button{
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  color: #fff;
  height: 60px;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 14px;
  background-color: $base-color;
}
div{
  font-family: $font-default;
}

.valeur{
  font-weight: bold;
  font-size: 17.6737px;
  line-height: 53%;
  color: #000000;
}
.sous-titre{
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
}
.titre{
  font-weight: bold;
  font-size: 32px;
  line-height: 140%;
}
.marque{
  font-size: 17px;
}
@media screen and (max-width: 700px){

  .button{
    width: 100%;
    font-size: 16px;
    height: 44px;
    border-radius: 10px;
  }
  .titre1{
    font-size: 16px;
    line-height: 17px;
  }
  .desc{
    font-size: 8.9375px;
    line-height: 17px;
  }
  .baniere{
    border-radius: 9.72764px;
    height: 66px;
  }
  .baniere1{
    border-radius: 10px;
    height: 60px;
    .titre{
      font-size: 19px;
    }
    .sous-titre{
      font-size: 7px;
    }
  }
}
</style>
