<template>
  <div class="w-full h-full">
    <div class="bas mt-6 pb-2">
      <div>
        <div class="flex items-center md:mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            Marque
          </div>
          <div class=" w-1/2 text-right valeur">
            <span v-if="donnes === null">{{devis.marque}}</span>
            <span v-if="donnes !== null"> {{donnes.meta.carBrand}} </span>
          </div>
        </div>

        <div class="flex items-center md:mb-5 md:mt-5 mt-4 pt-1 pb-1">
          <div class="w-1/2 denom">
            Modèle
          </div>
          <div class=" w-1/2 text-right valeur">
           <span v-if="donnes === null">{{devis.model}}</span>
            <span v-if="donnes !== null"> {{donnes.meta.carModel}} </span>
          </div>
        </div>

        <div class="flex items-center md:mb-5 md:mt-5 mt-4 pt-1 pb-1">
          <div class="w-1/2 denom">
            Carburant
          </div>
          <div class=" w-1/2 text-right valeur">
            <span v-if="donnes === null">{{ devis.carburant }}</span>
            <span v-if="donnes !== null"> <span v-if="donnes.meta.isDieselFuel">Diesel</span> <span v-if="!donnes.meta.isDieselFuel">Essence</span></span>
          </div>
        </div>

        <div class="flex items-center md:mb-5 md:mt-5 mt-4 pt-1 pb-1">
          <div class="w-1/2 denom">
            Immatriculation
          </div>
          <div class=" w-1/2 text-right valeur">
            <span v-if="donnes === null">{{devis.plaque}}</span>
            <span v-if="donnes !== null"> {{donnes.meta.carRegistration}} </span>
          </div>
        </div>

        <div class="flex items-center md:mb-5 md:mt-5 mt-4 pt-1 pb-1">
          <div class="w-1/2 denom">
            No. chassis
          </div>
          <div class=" w-1/2 text-right valeur">
            <span v-if="donnes === null">{{devis.valeur.chassi}}</span>
            <span v-if="donnes !== null"> {{donnes.meta.carFrameNumber}} </span>
          </div>
        </div>

        <div class="flex items-center md:mb-5 md:mt-5 mt-4 pt-1 pb-1">
          <div class="w-1/2 denom">
            Places assises
          </div>
          <div class=" w-1/2 text-right valeur">
            <span v-if="donnes === null">{{ devis.place }}</span>
            <span v-if="donnes !== null"> {{donnes.meta.carSeat}} </span>
          </div>
        </div>

        <div class="flex items-center mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            Puissance fiscale
          </div>
          <div class=" w-1/2 text-right valeur">
            <span v-if="donnes === null">{{ devis.puissance }}</span>
            <span v-if="donnes !== null"> {{donnes.meta.fiscalPower}} </span>
            CV
          </div>
        </div>

        <div class="flex items-center md:mb-5 md:mt-5 mt-4 pt-1 pb-1">
          <div class="w-1/2 denom">
            Mise en circulation
          </div>
          <div class=" w-1/2 text-right valeur">
            <span v-if="donnes === null">{{conversion(devis.valeur.dateMiseEnCirculation)}}</span>
            <span v-if="donnes !== null"> {{conversion(donnes.meta.carCirculationDate)}} </span>
          </div>
        </div>

        <div class="flex items-center md:mb-5 md:mt-5 mt-4 pt-1 pb-1">
          <div class="w-1/2 denom">
            Valeur d'achat
          </div>
          <div class="w-1/2 text-right valeur">
            <span v-if="donnes === null">{{ devis.valeur.valeurVehicule.toLocaleString() }}</span>
            <span v-if="donnes !== null"> {{donnes.meta.carAmount.toLocaleString()}} F</span>
          </div>
        </div>

        <div class="flex items-center md:mb-5 md:mt-6 mt-5">
          <div class="w-1/2 valeur">ASSURANCE</div>
        </div>

        <div class="flex items-center md:mb-5 md:mt-5 mt-4 pt-1 pb-1">
          <div class="w-1/2 denom">
            Couverture
          </div>
          <div class="w-1/2 text-right valeur">RC + CEDEAO</div>
        </div>

        <div class="flex items-center md:mb-5 md:mt-5 mt-4 pt-1 pb-1">
          <div class="w-1/2 denom">
            Durée
          </div>
          <div class="w-1/2 text-right valeur">
            <span v-if="donnes === null">{{devis.dure}}</span>
            <span v-if="donnes !== null">{{donnes.meta.duration}} Mois</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'index',
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      devis: null
    }
  },
  mounted () {
    this.devis = this.$store.getters.trackFirstDevis
  },
  methods: {
    conversion (index) {
      return new Date(index).toLocaleDateString()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";

div{
  font-family: $font-default;
}

.denom{
  font-style: normal;
  font-weight: normal;
  font-size: 17.6737px;
  line-height: 9px;
  color: #000000;
}
.valeur{
  font-weight: bold;
  font-size: 17.6737px;
  line-height: 53%;
  color: #000000;
}
.reduction{
  .denom{
    font-style: normal;
    font-weight: normal;
    font-size: 15.1489px;
    line-height: 9px;
    color: #F44336;
  }
  .valeur{
    font-weight: bold;
    font-size: 15.1489px;
    line-height: 53%;
    color: #F44336;
  }
}
.sous-titre{
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
}
.titre{
  font-weight: bold;
  font-size: 32px;
  line-height: 140%;
}

@media screen and (max-width: 700px){
  .denom, .valeur{
    font-size: 12px;
  }
}
</style>
